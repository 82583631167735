




















































































































































import Vue from "vue";
import axios from "axios";
import convert from "xml-js";

export default Vue.extend({
  data() {
    return {
      orders: [],
      loading: true
    };
  },

  created() {
    localStorage.token = atob(this.$router.currentRoute.query.code.toString());
    axios
      .post("https://progaming-italia.com/api/ebay/transactions", {
        token: localStorage.token
      })
      .then(response => {
        const orders = JSON.parse(
          convert.xml2json(response.data, { compact: true })
        );

        this.orders = orders.GetSellerTransactionsResponse.TransactionArray.Transaction;

        this.loading = false;
      });
  }
});
