
import Vue from "vue";
import axios from "axios";

export default Vue.extend({
  name: "EbayCallback",

  created() {
    axios.post(
      "https://progaming-italia.com/api/ebay/excangeCode", {
          "code":this.$router.currentRoute.query.code,
        }
    )
    .then(response => {
      localStorage.token = response.data.access_token;
      this.$router.push('/transactions');
    })
  },
});
