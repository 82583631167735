

































import Vue from "vue";

export default Vue.extend({
  name: "Home",

  methods: {
    login() {
      location.replace(
        "https://auth.ebay.com/oauth2/authorize?client_id=MarcoRib-JJCF-PRD-5d4b27d4f-8b8dbe1f&response_type=code&state=homestate&response_type=code&redirect_uri=Marco_Ribera-MarcoRib-JJCF-P-cfjmr&scope=https://api.ebay.com/oauth/api_scope https://api.ebay.com/oauth/api_scope/sell.marketing.readonly https://api.ebay.com/oauth/api_scope/sell.marketing https://api.ebay.com/oauth/api_scope/sell.inventory.readonly https://api.ebay.com/oauth/api_scope/sell.inventory https://api.ebay.com/oauth/api_scope/sell.account.readonly https://api.ebay.com/oauth/api_scope/sell.account https://api.ebay.com/oauth/api_scope/sell.fulfillment.readonly https://api.ebay.com/oauth/api_scope/sell.fulfillment https://api.ebay.com/oauth/api_scope/sell.analytics.readonly https://api.ebay.com/oauth/api_scope/sell.finances https://api.ebay.com/oauth/api_scope/sell.payment.dispute https://api.ebay.com/oauth/api_scope/commerce.identity.readonly"
      );
    }
  }
});
